<style lang="less" scoped>
.cop-salary-my {
  overflow: auto;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background: #FFF;
  flex: 1;
}
.time {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 800;
}
.real-wage {
  font-size: 18px;
  color: #ff9900;
}
.detail {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .wrap {
    width: 20%;position: relative;
    padding: 2px;
  }
}
</style>

<template>
  <div style="flex-direction: column; display: flex;">
    <div style="background-color: #FFF;margin: 10px 10px 0 10px;border-radius: 5px;">
      <fm-title title-text="我的工资"/>
    </div>
    <div class="cop-salary-my">
      <Timeline>
        <TimelineItem v-for="item in dataList" :key="item.id">
          <Row class="time" align="middle" :gutter="20">
            <i-col>日期:</i-col>
            <i-col>{{ item.month }}</i-col>
            <i-col>实发工资:</i-col>
            <i-col>¥&nbsp;<span class="real-wage">{{ item.realWage }}</span></i-col>
          </Row>
          <div class="detail">
            <div class="wrap" v-for="field in item.columns" :key="field.key">
              <Tag size="large" style="margin: 0;width: 100%;display: block;">{{ field.title }}: {{ item[field.key] }}</Tag>
            </div>
          </div>
        </TimelineItem>
      </Timeline>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
import { getColumnConfigs } from './lib'

export default {
  data () {
    return {
      dataList: []
    }
  },
  methods: {
    async loadData () {
      this.$Spin.show()
      try {
        const data = await request('/basis/hrms_wage/my')
        const dataList = data.map(async item => {
          item.type = item.type ? Number(item.type) : item.type
          let obj = {}
          let detail = JSON.parse(item.detail)
          let { columns } = await getColumnConfigs(item.type, this.$datetime.format(item.month, 'YM'))
          columns.filter(v => v.original).forEach(v => obj[v.key] = item[v.key])
          columns.filter(v => v.import !== false && v.original !== true).forEach(v => obj[v.key] = detail[v.key])
          obj.detail = item.detail
          obj.columns = columns
          obj.month = item.month
          return obj
        })
        this.dataList = await Promise.all(dataList)
      } catch (e) {
        this.$Modal.error({
          title: '系统提示',
          content: e.message
        })
      }
      this.$Spin.hide()
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
